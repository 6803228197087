<template>
    <tabela-usuarios />
</template>

<script>
import Table from "./Table.vue";

export default {
  data() {
    return {
      localizacao: "Usuários",
    };
  },
  name: "MainComp",
  components: {
    "tabela-usuarios": Table,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem('token');

      if(geToken == null) {
        this.$router.push('/') 
      }
    }
  }
};
</script>

