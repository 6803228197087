<template>
  <div class="container">
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.atas"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>
                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.estatutos"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>
                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>

                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.leis"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>
                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>

                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.protocolos"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>
                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>

                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.rateios"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>

                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>

                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.programas"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>

                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>

                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="12"
              v-for="item in resource.contratos"
              :key="item.id"
            >
              <div class="boxFolder">
                <v-icon class="icone" large> mdi-folder </v-icon>
                <span v-text="item.name"></span>
                <span v-text="item.year" class="font-weight-bold"></span>

                <div class="boxAction">
                  <v-btn
                    v-if="token != null"
                    class="mr-4"
                    elevation="2"
                    fab
                    x-small
                  >
                    <v-icon color="red darken-4" @click="apagarArquivo(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <a
                    v-if="item.link.startsWith('http')"
                    :href="item.link"
                    target="_blank"
                    download
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>

                  <a
                    v-else
                    :href="storage+item.link"
                    target="_blank"
                  >
                    <v-btn elevation="2" fab x-small>
                      <v-icon color="blue darken-3"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ menssagem }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import config  from '../../config/config'
export default {
  data() {
    return {
      token: "",
      menssagem: "",
      storage: '',
      snackbar: false,
      timeout: 2000,
    };
  },
  props: {
    resource: {
      type: Object,
    },
  },

  created() {
    this.initialize();
    this.storage = config.baseUrlSite;
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem("token");
      this.token = geToken;
    },
    apagarArquivo(item) {
      if (item.folder_id == 1) {
        let listAtas = this.resource.atas;
        let newArray = listAtas.filter(function (listAtas) {
          return listAtas.id != item.id;
        });
        this.resource.atas = newArray;
      }

      if (item.folder_id == 2) {
        let listEstatutos = this.resource.estatutos;
        let newArray = listEstatutos.filter(function (listEstatutos) {
          return listEstatutos.id != item.id;
        });
        this.resource.estatutos = newArray;
      }

      if (item.folder_id == 3) {
        let listLeis = this.resource.leis;
        let newArray = listLeis.filter(function (listLeis) {
          return listLeis.id != item.id;
        });
        this.resource.leis = newArray;
      }

      if (item.folder_id == 4) {
        let listProtocolos = this.resource.protocolos;
        let newArray = listProtocolos.filter(function (listProtocolos) {
          return listProtocolos.id != item.id;
        });
        this.resource.protocolos = newArray;
      }

      if (item.folder_id == 5) {
        let listRateios = this.resource.rateios;
        let newArray = listRateios.filter(function (listRateios) {
          return listRateios.id != item.id;
        });
        this.resource.rateios = newArray;
      }

      if (item.folder_id == 6) {
        let listProgramas = this.resource.programas;
        let newArray = listProgramas.filter(function (listProgramas) {
          return listProgramas.id != item.id;
        });
        this.resource.programas = newArray;
      }

      // ID de contratos no BD de Produção é 41 mas em Desenv é 7
      // por isso a alteraçaõ do ID no codigo para em produção funcionar
      if (item.folder_id == 41) {
        let listContratos = this.resource.contratos;
        let newArray = listContratos.filter(function (listContratos) {
          return listContratos.id != item.id;
        });
        this.resource.contratos = newArray;
      }

      axios
        .delete(`/document/${item.id}`)
        .then((response) => {
          this.menssagem = response.data.message;
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.boxFolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 150px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
  transition: 0.5s;
}

.boxFolder:hover {
  border: 1px solid #18cf28;
  transition: 0.5s;
}

.boxFolder > span {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
}

.boxAction {
  display: flex;
  margin-top: 10px;
}

.icone {
  color: #ee5253;
}
</style>
