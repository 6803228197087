<template>
  <div class="card">

    <div class="boxText">
      <h3 class="textInfo">Policlínicas do Consórcio</h3>
      <div class="linha"></div>
    </div>

    <v-expansion-panels>
      <v-expansion-panel v-for="dado in resource" :key="dado.id">

        <v-expansion-panel-header>
          <h3 class="textHeader">{{ dado.name }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div class="boxInformations">
            <h4>Diretor: </h4>
            <h5 v-text="dado.director"></h5>
          </div>
          <div class="boxInformations">
            <h4>Telefone: </h4>
            <h5 v-text="dado.director_phone"></h5>
          </div>
          <div class="boxInformations">
            <h4>E-mail: </h4>
            <h5 v-text="dado.director_email"></h5>
          </div>

          <edit-comp :edit="dado"/>

        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>
    
  </div>
</template>

<script>

import EditPoliclinicaComp from './EditPoliclinicaComp.vue'

export default {

  props:{
    resource: {
      type: Array
    }
  },

	name: "PoliclinicasComp",
  components: {
    "edit-comp": EditPoliclinicaComp
  },

}
</script>

<style>
  .card{
    background: #FFFFFF;
    
    padding: 35px;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .boxText{
    margin-bottom: 20px;
  }

  .boxText > h3 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .boxText > .linha {
    background: #18e79a;
    width: 75px;
    height: 4px;
    border-radius: 25px;
  }

  .textHeader{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
  }

  .boxInformations{
    display: flex;
    margin-bottom: 5px;
  }

  .boxInformations > h5{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
  }

  .boxInformations > h4{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 13px;
    margin-right: 5px;
  }

  .textBtn, .btnText{
    font-family: 'Poppins';
		font-weight: 400;
		font-size: 14px;
		text-transform: none;
  }

  .btnTextCancel{
		font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
	}
</style>