<template>
  <div class="Modal">
    <v-btn
      class="rounded-lg"
      elevation="0"
      color="primary"
      outlined
      @click="dialog = true"
      v-if="token != null"
    >
      <v-icon small class="mr-2"> mdi-pencil </v-icon>
      <span class="textBtn"> Editar Municípios </span>
    </v-btn>

    <v-dialog v-model="dialog" width="800px" class="modelDialog" persistent>
      <v-card class="card rounded-lg">
        <div class="banner-all">
          <h4>Municípios Consórciados</h4>
        </div>

        <div class="selecionados">
          <v-row>
            <div v-for="(selection, i) in resource" :key="selection.name">
              <v-col>
                <v-chip
                  :disabled="loading"
                  text-color="white"
                  color="#27ae60"
                  close
                  @click:close="resource.splice(i, 1)"
                  label
                  class="shrink"
                >
                  <v-icon left small>mdi-city</v-icon>
                  <span class="textChip">{{ selection.name }}</span>
                </v-chip>
              </v-col>
            </div>
          </v-row>
        </div>

        <div class="banner-all">
          <h4>Municípios Consórciados</h4>
        </div>

        <div v-if="!allSelected" c cols="12">
          <v-text-field
            ref="search"
            v-model="search"
            full-width
            outlined
            dense
            label="Buscar cidade"
            placeholder="Digite o nome da cidade"
          ></v-text-field>
        </div>

        <v-list class="listaMunicipios" dense>
          <template v-for="item in categories">
            <v-list-item
              v-if="!resource.includes(item)"
              :key="item.id"
              :disabled="loading"
              @click="resource.push(item)"
            >
              <v-list-item-avatar>
                <v-icon :disabled="loading" small>mdi-city</v-icon>
              </v-list-item-avatar>
              <v-list-item-title
                class="textList"
                v-text="item.name"
              ></v-list-item-title>
            </v-list-item>
          </template>
        </v-list>

        <v-card-actions class="boxBtn">
          <v-spacer></v-spacer>

          <v-btn
            class="rounded-lg btn"
            color="red"
            text
            elevation="0"
            @click="dialog = false"
          >
            <span class="btnTextCancel">Cancelar</span>
          </v-btn>

          <v-btn
            :disabled="!resource.length"
            class="rounded-lg"
            color="success"
            elevation="0"
            @click="next"
          >
            <span class="btnText">Enviar dados</span>
            <v-icon small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      dados: [],
      dialog: false,
      items: [],
      search: "",
      selected: [],
      snackbar: false,
      text: "",
      timeout: 2000,
      loading: false,
      token: "",
    };
  },

  props: {
    resource: {
      type: Array,
    },
  },

  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },

    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.name.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },

    selections() {
      const selections = [];

      for (const selection of this.resource) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selected() {
      this.search = "";
    },
  },

  name: "ModifyMunicipiosComp",

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem("token");
      this.token = geToken;
      
      axios
        .get(`/consortia/${this.$route.params.id}/cities`)
        .then((response) => {
          this.dados = response.data;
        });

      axios.get(`/cities?consortium_id`).then((response) => {
        this.items = response.data;
      });

      axios
        .get(`/cities?consortium_id=${this.$route.params.id}`)
        .then((response) => {
          this.selected = response.data;
        });
    },
    next() {
      this.loading = true;

      const infos = this.resource.map(function (element) {
        return element.id;
      });

      axios
        .put(`/consortia/${this.$route.params.id}/cities`, {
          cities: infos,
        })
        .then((response) => {
          this.search = "";
          this.dialog = false;
          this.snackbar = true;
          this.text = response.data.message;
        });
    },
  },
};
</script>

<style scoped>
.card {
  padding: 30px;
}

.modelDialog {
  padding: 30px;
}

.textBtn {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;

  text-transform: none;
}

.banner-all {
  width: 100%;
  height: 40px;

  padding: 10px;
  margin-bottom: 5px;

  background: black;

  border-radius: 8px;

  display: flex;
  align-items: center;
}

.banner-all > h4 {
  font-family: "Poppins";
  font-weight: 400;
  color: white;

  font-size: 14px;
}

.card > .selecionados {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 10px;

  padding: 10px;
  margin-bottom: 25px;

  overflow-y: scroll;
  overflow-x: hidden;

  height: 150px;
}

.listaMunicipios {
  border: 1px solid #c4c4c4;
  border-radius: 8px !important;

  margin-top: -20px;
  overflow-y: scroll;
  height: 200px;
}

.textChip {
  font-family: "Poppins";
  font-size: 13px;
}

.textList {
  font-family: "Poppins";
  font-size: 16px;
}

.boxBtn {
  margin-top: 25px;
}

.btnText {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;

  text-transform: none;
}

.btnTextCancel {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;

  text-transform: none;
}
</style>