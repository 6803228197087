<template>
  <div class="modal">

    <v-btn class="rounded-lg mt-5" elevation="0" color="primary" outlined @click="dialog = true" v-if="token != null">
      <v-icon small class="mr-2">
          mdi-pencil
      </v-icon>
      <span class="textBtn">
          Editar Hospital
      </span>
    </v-btn>

    <v-dialog v-model="dialog" width="800px" class="modelDialog" persistent v-if="token != null">
      
      <v-card class="card rounded-lg">

        <div class="banner mb-5">
          <h4>Hospital</h4>
        </div>

        <v-form @submit.prevent="modify" ref="form">
          <v-text-field label="Policlínica" placeholder="Placeholder" outlined v-model="edit.name" dense></v-text-field>
          <v-text-field label="Diretor" placeholder="Placeholder" outlined v-model="edit.director" dense></v-text-field>
          <v-text-field label="Telefone" placeholder="Placeholder" outlined v-model="edit.director_phone" dense></v-text-field>
          <v-text-field label="E-mail" placeholder="Placeholder" outlined v-model="edit.director_email" dense></v-text-field>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="rounded-lg btn" color="red" text elevation="0" @click="dialog = false">
              <span class="btnTextCancel">Cancelar</span>    
            </v-btn>

            <v-btn class="rounded-lg" color="success" elevation="0" type="submit">
              <span class="btnText">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import axios from '@/axios'

export default {
  name: "EditPoliclinicaComp",

  data(){
    return {
      dialog: false,
      token: ""
    }
  },

  props:{
    edit: {
      type: Object
    }
  },
  
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem("token");
      this.token = geToken;
    },

    modify () {
	
      const infos = new Object();
        infos.name = this.edit.name
        infos.director = this.edit.director
        infos.director_phone = this.edit.director_phone
        infos.director_email = this.edit.director_email
        infos.consortium_id = this.edit.consortium_id

      axios.put(`/hospitals/${this.edit.id}`, infos)
      .then((response) => {
        console.log(response);

        // this.search = ''
        this.dialog = false;
        // this.snackbar = true;
        // this.text = response.data.message
      });
    },
  }

}
</script>

<style>

</style>