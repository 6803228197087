<template>
  <v-card class="card-archive rounded-lg" elevation="0">
    <v-toolbar color="#000000" dark flat>
      <div class="options">
        <h4>Documentos Constitutivos</h4>
        <v-btn color="#3d3d3d" dark @click="dialog = true" v-if="token != null">
          Adicionar Documento
        </v-btn>
      </div>

      <v-row>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
          v-if="token != null"
        >
          <v-card class="cardModal">
            <v-card-title>
              <span class="text-h5">Novo Documento</span>
            </v-card-title>
            <v-form @submit.prevent="sendInfos" ref="infos">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="name"
                        label="Nome do arquivo"
                        :rules="formRules"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="folder"
                        :items="dados"
                        :rules="formRules"
                        item-text="description"
                        item-value="id"
                        required
                        outlined
                        dense
                        label="Selecione o tipo do documento"
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        v-model="file"
                        type="file"
                        accept=".pdf"
                        label="Selecione um documento"
                        :rules="formRules"
                        required
                        clearable
                        dense
                        outlined
                      >
                      </v-file-input>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="dateDoc"
                        :items="dadosYear"
                        :rules="formRules"
                        item-text="year"
                        item-value="id"
                        required
                        outlined
                        dense
                        label="Selecione o ano"
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="obs"
                        dense
                        outlined
                        label="Observação"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog = false">
                  Fechar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  dark
                  type="submit"
                  :loading="carregamento"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </v-toolbar>

    <hr />

    <v-tabs
      class="spaceIn"
      centered
      show-arrows
      v-model="tabs"
      background-color="#1e272e"
      dark
      slider-color="#ffffff">
      <v-tab>ATAS</v-tab>
      <v-tab>ESTATUTOS</v-tab>
      <v-tab>LEIS AUTORIZATIVAS / RATIFICADORAS</v-tab>
      <v-tab>PROTOCOLO DE INTENÇÕES</v-tab>
      <v-tab>RATEIO</v-tab>
      <v-tab>PROGRAMA</v-tab>
      <v-tab>CONTRATOS DE CONSÓRCIO</v-tab>
    </v-tabs>

    <v-tabs-items class="space" v-model="tabs">
      <DadosArquivosComp :resource="infos" />
    </v-tabs-items>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ menssagem }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import DadosArquivosComp from "./DadosArquivosComp.vue";

import axios from "@/axios";

export default {
  name: "ArquivosComp",
  data() {
    return {
      formRules: [(v) => !!v || "Campo obrigatório."],
      tabs: null,
      dialog: false,
      dados: "",
      dadosYear: "",
      name: "",
      folder: "",
      file: [],
      dateDoc: "",
      obs: "",
      token: "",
      snackbar: false,
      menssagem: "",
      timeout: 2000,
      menu: false,
      carregamento: false,

      infos: {
        atas: [],
        estatutos: [],
        leis: [],
        protocolos: [],
        rateios: [],
        programas: [],
        contratos: [],
      },
    };
  },

  components: {
    DadosArquivosComp,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem("token");
      this.token = geToken;

      axios.get(`/folder`).then((response) => {
        this.dados = response.data;
      });

      axios.get(`/document-years`).then((response) => {
        this.dadosYear = response.data;
      });

      axios.get(`/document/${this.$route.params.id}`).then((response) => {
        let documentos = response.data;

        this.infos.atas = documentos.filter(function (doc) {
          return doc.folder_id == 1;
        });

        this.infos.estatutos = documentos.filter(function (doc) {
          return doc.folder_id == 2;
        });

        this.infos.leis = documentos.filter(function (doc) {
          return doc.folder_id == 3;
        });

        this.infos.protocolos = documentos.filter(function (doc) {
          return doc.folder_id == 4;
        });

        this.infos.rateios = documentos.filter(function (doc) {
          return doc.folder_id == 5;
        });
        this.infos.rateios.sort(function (a, b) {
          if (a.year > b.year) {
            return -1;
          } else {
            return true;
          }
          
        });

        this.infos.programas = documentos.filter(function (doc) {
          return doc.folder_id == 6;
        });

        this.infos.programas.sort(function (a, b) {
          if (a.year > b.year) {
            return -1;
          } else {
            return true;
          }
        });

        // ID de contratos no BD de Produção é 41 mas em Desenv é 7 
        // por isso a alteraçaõ do ID no codigo para em produção funcionar
        this.infos.contratos = documentos.filter(function (doc) {
          return doc.folder_id == 41;
        });

        this.infos.contratos.sort(function (a, b) {
          if (a.year > b.year) {
            return -1;
          } else {
            return true;
          }
        });
        
      });
    },

    sendInfos() {
      if (this.$refs.infos.validate()) {
        let newName = this.name + ".pdf";

        let formData = new FormData();
        formData.append("name", newName);
        formData.append("folder", this.folder);
        formData.append("document", this.file);
        formData.append("year", this.dateDoc);
        formData.append("observation", this.obs);

        this.carregamento = true;
        axios
          .post(`/document/${this.$route.params.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
           
            this.initialize();
            this.menssagem = response.data.message;
            this.snackbar = true;
            this.dialog = false;
            this.carregamento = false;

            this.$refs.infos.resetValidation();
            this.$refs.infos.reset();
          });
      }
    },
  },
};
</script>

<style scoped>
.btnModal {
  position: absolute;
  top: 15px;
  right: 15px;
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.options > h4 {
  font-family: "Poppins";
  font-weight: 600;
  color: white;

  font-size: 18px;
}

.cardModal {
  padding: 20px;
}
</style>
