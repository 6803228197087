<template>
  <v-card
    class="header d-flex align-center justify-space-between rounded-lg mb-5"
    elevation="0"
  >
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <div class="left">
          <router-link class="link" to="/">
            <v-btn
              class="btnReturn rounded-lg"
              elevation="0"
              color="#000"
              outlined
              ><v-icon class="icon"> mdi-arrow-left</v-icon></v-btn
            >
            </router-link>
          <div class="box rounded-lg">
            <v-icon class="icon"> mdi-web</v-icon>
            <h4>{{ dados.complete_name }}</h4>
          </div>
        </div>
      </v-col>

      
    </v-row>
  </v-card>
</template>

<script>

import axios from '@/axios'

export default {
  name: "HeaderComp",
  data(){
    return{
      dados: [],
    }
  },

  created(){
    this.initialize()
  },

  methods: {
    initialize () {
        axios.get(`/consortia/${this.$route.params.id}`)
          .then((response) => {
            this.dados = response.data
          })
      },
  }
};
</script>

<style scoped>

.header {
  background: transparent;
}

.left {
  display: flex;
  align-items: center;
}

.left > .link{
  text-decoration: none;
}

.left > .link > .btnReturn {
  height: 40px;
  min-width: 40px;
  width: 40px;
}

.left > .box {
  display: flex;
  align-items: center;

  background: #000;

  height: 40px;
  width: 100%;
  padding: 10px;
  margin-left: 10px;
}

.left > .box > .icon {
  color: #fff;
  margin-right: 10px;
}

.left > .box > h4 {
  color: #fff;
  font: 13px "Poppins";
  font-weight: 600;
}

.right {
  display: flex;
  justify-content: end;
}

/* Responsivo */
@media only screen and (max-width: 660px) {
  .right {
    display: flex;
    justify-content: center;
  }

  .left > .box > h4 {
    color: #fff;
    font: 10px "Poppins";
    font-weight: 600;
  }
}
</style>