<template>
  <div class="">
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <policlinica-comp :resource="resourcePoliclinica" />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <hospital-comp :resource="resourceHospital" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@/axios";

import PoliclinicasComp from "./PoliclinicasComp.vue";
import HospitaisComp from "./HospitaisComp.vue";

export default {
  data() {
    return {
      resourcePoliclinica: [],
      resourceHospital: [],
    };
  },
  name: "UnidadesComp",
  components: {
    "policlinica-comp": PoliclinicasComp,
    "hospital-comp": HospitaisComp,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios
        .get(`/consortia/${this.$route.params.id}/polyclinics`)
        .then((response) => {
          this.resourcePoliclinica = response.data;
        });

      axios
        .get(`/consortia/${this.$route.params.id}/hospitals`)
        .then((response) => {
          this.resourceHospital = response.data;
        });
    },
  },
};
</script>

<style>
</style>