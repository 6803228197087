<template>
  <v-container class="main">
    <header-comp />
    <options-comp />
  </v-container>
</template>

<script>
import HeaderComp from "./HeaderComp.vue";
import MenuOptions from "./MenuOptions.vue";

export default {
  data() {
    return {
      localizacao: "Ficha Técnica",
    };
  },
  name: "MainComp",
  components: {
    "header-comp": HeaderComp,
    "options-comp": MenuOptions,
  },
};
</script>

<style scoped>
.nav {
  padding: 30px;
}
.nav > h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}
</style>
