<template>
  <div class="Modal">
    <v-btn
      class="rounded-lg"
      elevation="0"
      color="primary"
      outlined
      @click="dialog = true"
      v-if="token != null"
    >
      <v-icon small class="mr-2"> mdi-pencil </v-icon>
      <span> Editar Informações </span>
    </v-btn>

    <v-dialog v-model="dialog" width="800px" persistent v-if="token != null">
      <v-card class="card rounded-lg">
        <v-form @submit.prevent="modify" ref="form">
          <v-row>
            <v-col class="labelInput" cols="12" md="12">
              <span>Presidente</span>
            </v-col>

            <v-col cols="12" md="7" class="noSpace">
              <v-text-field
                label="Nome Presidente"
                placeholder="Nome Presidente"
                outlined
                dense
                v-model="resource.president"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="noSpace">
              <v-text-field
                label="E-mail Presidente"
                placeholder="E-mail Presidente"
                outlined
                dense
                v-model="resource.president_email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="labelInput" cols="12" md="12">
              <span>Vice-Presidente</span>
            </v-col>

            <v-col cols="12" md="7" class="noSpace">
              <v-text-field
                label="Nome Vice-Presidente"
                placeholder="Nome Presidente"
                outlined
                dense
                v-model="resource.vice_president"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="noSpace">
              <v-text-field
                label="E-mail Vice-Presidente"
                placeholder="Nome Vice-Presidente"
                outlined
                dense
                v-model="resource.vice_president_email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" class="labelInput">
              <span>Diretor Executivo</span>
            </v-col>

            <v-col cols="12" md="7" class="noSpace">
              <v-text-field
                label="Nome Diretor Executivo"
                placeholder="Nome Diretor Executivo"
                outlined
                dense
                v-model="resource.director"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="noSpace">
              <v-text-field
                label="E-mail Diretor Executivo"
                placeholder="E-mail Diretor Executivo"
                outlined
                dense
                v-model="resource.director_email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" class="labelInput">
              <span>Endereço</span>
            </v-col>

            <v-col cols="12" md="12" class="noSpace">
              <v-text-field
                label="Endereço"
                placeholder="Endereço"
                outlined
                dense
                v-model="resource.address"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="noSpace">
              <span>CNPJ</span>
              <v-text-field
                label="CNPJ"
                placeholder="CNPJ"
                outlined
                dense
                v-model="resource.cnpj"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="noSpace">
              <span>Site</span>
              <v-text-field
                label="Site"
                placeholder="Site"
                outlined
                dense
                v-model="resource.site"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions class="boxBtn">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="red"
              text
              elevation="0"
              @click="dialog = false"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg btn"
              color="success"
              elevation="0"
              type="submit"
            >
              <span>Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "ModifyInfosComp",
  props: {
    resource: {
      type: Object,
    },
  },

  data() {
    return {
      dados: [],
      dialog: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      token: "",
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem("token");
      this.token = geToken;

      axios.get(`/consortia/${this.$route.params.id}`).then((response) => {
        this.dados = response.data;
      });
    },

    modify() {
      //   this.$refs.form.validate()

      const infos = new Object();
      infos.president = this.resource.president;
      infos.president_email = this.resource.president_email;
      infos.vice_president = this.resource.vice_president;
      infos.vice_president_email = this.resource.vice_president_email;
      infos.director = this.resource.director;
      infos.director_email = this.resource.director_email;
      infos.address = this.resource.address;
      infos.cnpj = this.resource.cnpj;
      infos.site = this.resource.site;

      axios
        .put(`/consortia/${this.$route.params.id}`, infos)
        .then((response) => {

          this.dialog = false;
          this.snackbar = true;

          this.text = response.data.message;

          // this.carregamento = true
          // this.disativado = true

          // setTimeout(() => {
          //   this.carregamento = false
          //   this.disativado = false

          //   this.btnStatus = "Logado com sucesso!"
          //   localStorage.setItem('token', response.data.token);
          //   setTimeout(() => {this.$router.push({ name: 'Consorcios' })}, 1000);
          // }, 1500);
        })

        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
.card {
  padding: 30px;
}

.labelInput {
  padding: 0 15px 5px 15px !important;
}

.noSpace {
  padding: 0 15px !important;
}

.labelInput > span,
.noSpace > span {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;

  text-transform: none;
}

span {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;

  text-transform: none;
}
</style>
