<template>
  <div class="tableOptions">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="dialogEditar = true">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="iconeDeletar" small @click="dialogAPagar = true">
            mdi-delete
          </v-icon>
        </span>
      </template>
      <span>Apagar</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="800px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner">
          <h4>Editar Informações</h4>
        </div>

        <v-form
          @submit.prevent="edit"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            label="Nome"
            placeholder="Placeholder"
            outlined
            v-model="resource.name"
            :rules="nameRules"
            requerid
            dense
          ></v-text-field>
          <v-text-field
            label="E-mail"
            placeholder="Placeholder"
            outlined
            v-model="resource.email"
            :rules="nameRules"
            requerid
            dense
          ></v-text-field>
          <v-text-field
            label="CPF"
            placeholder="Placeholder"
            outlined
            v-model="resource.cpf"
            v-mask="'###.###.###-##'"
            :rules="nameRules"
            requerid
            dense
          ></v-text-field>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="red"
              text
              elevation="0"
              @click="dialogEditar = false"
            >
              <span class="textBtn">Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="success"
              elevation="0"
              type="submit"
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner">
          <h4>Deletar Usuário</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar <span class="userName">{{ resource.name }}</span> dos
            usuários cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter">
          <v-btn
            class="rounded-lg btn"
            color="red"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span class="textBtn">Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="success"
            elevation="0"
            type="submit"
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "EditDeletUserComp",

  props: {
    resource: {
      type: Object,
    },
  },

  data() {
    return {
      dialogEditar: false,
      dialogAPagar: false,
      snackbar: false,
      text: "",
      timeout: 2000,

      valid: true,
      nameRules: [
        (v) => !!v || "O nome é obrigatório",
        (v) => (v && v.length >= 5) || "Preencha com o nome e sobrenome.",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      emailRules: [
        (v) => !!v || "O e-mail é obrigatório",
        (v) => /.+@.+/.test(v) || "Insira um e-mail válido",
      ],
    };
  },

  methods: {
    edit() {
      this.$refs.form.validate();
      const newCPF = this.resource.cpf.replace(/[^a-zA-Z0-9]/g, "");
      const dados = new Object();
      dados.id = this.resource.id;
      dados.name = this.resource.name;
      dados.cpf = newCPF;
      dados.email = this.resource.email;

      axios
        .put(`/users/${dados.id}`, dados)
        .then((response) => {
          this.dialogEditar = false;
          this.text = response.data.message;
          this.snackbar = true;
        });
    },

    deletar() {
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>

<style scoped>
.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 35px;
}

.banner {
  background: #000000;
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 8px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  color: #ad2831;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  text-transform: none;
}
</style>
