<template>
  <div>

    <v-card class="cardmenu rounded-lg mb-5" elevation="0">

      <div class="boxNavegation">
        <h3>Informações do Consórcio</h3>
        <div class="menu">
          <div class="box">
            <v-icon class="iconNavegation active" :class="{ activeOne: active }"  @click="infos">mdi-information</v-icon>
            <span>Ficha Técnica</span>
          </div>
          <div class="box">
            <v-icon class="iconNavegation" :class="{ activeTwo: activeOther }"  @click="folder">mdi-folder</v-icon>
            <span>Documentos</span>
          </div>
        </div>
      </div>
    
    </v-card>

    <v-card class="cardInfos rounded-lg mb-5" elevation="0">

      <div v-if="status==true">
        <infos-comp />
        <municipios-comp />
        <unidades-comp />
      </div>
      <div v-else>
        <arquivos-comp />
      </div>
    </v-card>

  </div>

</template>

<script>

import axios from '@/axios'

import InfosComp from "./InfosComp.vue";
import MunicipiosComp from "../Municipios/MunicipiosComp.vue";
import UnidadesComp from "../Unidades/UnidadesComp.vue";
import ArquivosComp from "../Arquivos/ArquivosComp.vue";

export default {
  name: "MenuOptions",
  components: {
    "infos-comp": InfosComp,
    "municipios-comp": MunicipiosComp,
    "unidades-comp": UnidadesComp,
    "arquivos-comp": ArquivosComp,
  },

  data(){
    return{
      dados: [],
      status: true,
      active: true,
      activeOther: false
    }
  },

  created(){
    this.initialize()
  },

  methods: {
    initialize () {
      axios.get(`/consortia/${this.$route.params.id}`)
        .then((response) => {
          this.dados = response.data
        })
    },
    infos(){
      this.status = true
      this.active = true
      this.activeOther = false
    },
    folder(){
      this.status = false
      this.active = false
      this.activeOther = true
    }
  }
};
</script>

<style scoped>
  .cardmenu {
    padding: 20px 35px;
  }

  .cardInfos {
    background: transparent;
  }

  .boxNavegation{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .boxNavegation > h3{
    font-family: 'Poppins';
  }

  .boxNavegation > .menu{
    display: flex;
  }

  .boxNavegation > .menu > .box{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px 15px;
  }

  .boxNavegation > .menu > .box > .iconNavegation{
    background: #e2e2e2;
    padding: 5px;
    border-radius: 50%;
  }

  .boxNavegation > .menu > .box > span{
    font-family: 'Poppins';
    font-size: 12px;
  }

  .boxNavegation > .menu > .box >  .iconNavegation.activeOne,
  .boxNavegation > .menu > .box > .iconNavegation.activeTwo{
    background: #000;
    color: #fff;
    padding: 5px;
    border-radius: 50%;
  }
</style>