<template>
  <div class="Infos">

    <div class="infoConsorcio">
      <div class="boxText">
        <h3 class="textInfo">Ficha Técnica</h3>
        <div class="linha"></div>
      </div>
        <modify-infos :resource="resource" />
      </div>
    <v-row>

      <v-col cols="12" md="6" sm="12">
        <div class="boxInfos">
          <h4>Presidente</h4>
          <span>
            <v-icon class="iconInfo" small>mdi-account</v-icon>
            {{ dados.president }}
          </span>
          <small>
            <v-icon class="iconInfo" small>mdi-email</v-icon>
            {{ dados.president_email }}
          </small>
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <div class="boxInfos">
          <h4>Vice-Presidente </h4>
          <span>
            <v-icon class="iconInfo" small>mdi-account</v-icon>
            {{ dados.vice_president }}
          </span>
          <small>
            <v-icon class="iconInfo" small>mdi-email</v-icon>
            {{ dados.vice_president_email }}
          </small>
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <div class="boxInfos">
          <h4>Diretor Executivo</h4>
          <span>
            <v-icon class="iconInfo" small>mdi-account</v-icon>
            {{ dados.director }}
          </span>
          <small>
            <v-icon class="iconInfo" small>mdi-email</v-icon>
            {{ dados.director_email }}
          </small>
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <div class="boxInfos">
          <h4>Endereço</h4>
          <span>
            <v-icon class="iconInfo" small>mdi-office-building</v-icon>
            Sede
          </span>
          <span>
            <v-icon class="iconInfo" small>mdi-map-marker</v-icon>
            {{ dados.address }}
          </span>
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <div class="boxInfos">
          <h4>CNPJ</h4>
          <span>
            <v-icon class="iconInfo" small>mdi-file-document</v-icon>
            {{ dados.cnpj | VMask('##.###.###/####-##')}}
          </span>
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <div class="boxInfos">
          <h4>Site</h4>
          <span>
            <v-icon class="iconInfo" small>mdi-web</v-icon>
            <a :href="dados.site" target="_blank">Clique aqui</a>
          </span>
        </div>
      </v-col>

    </v-row>
  
  </div>
</template>

<script>

import axios from '@/axios'

import ModifyInfosComp from './ModifyInfosComp.vue'

export default {
  name: "InfosComp",
  components: {
    "modify-infos": ModifyInfosComp
  },

  data(){
    return{
      dados: [],
      resource: {},
    }
  },

  created(){
    this.initialize()
  },

  methods: {
    initialize () {
      axios.get(`/consortia/${this.$route.params.id}`)
        .then((response) => {
          this.dados = response.data
          this.resource = response.data
        })
    },
  }
};
</script>

<style scoped>

.boxText {
  margin-bottom: 25px;
}

.Infos {
  background: #FFFFFF;
  padding: 35px;
  margin-bottom: 20px;
}

.Infos > .infoConsorcio {
  display: flex;
  justify-content: space-between;
}

.Infos > .infoConsorcio > .boxText > h3 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.Infos > .infoConsorcio > .boxText > .linha {
  background: #18e79a;
  width: 75px;
  height: 4px;
  border-radius: 25px;
}

.Infos > .infoConsorcio > button {
  text-transform: none;
}

.boxInfos{
  display: flex;
  flex-direction: column;
}

.boxInfos > span > .iconInfo,
.boxInfos > small > .iconInfo{
  color: black;
}

.boxInfos > span,
small, a {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
}

.boxInfos > span > a {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;

  margin-left: 5px;
}

.boxInfos > h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
}

</style>