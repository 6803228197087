import { render, staticRenderFns } from "./EditDeletUserComp.vue?vue&type=template&id=e0d6b35a&scoped=true&"
import script from "./EditDeletUserComp.vue?vue&type=script&lang=js&"
export * from "./EditDeletUserComp.vue?vue&type=script&lang=js&"
import style0 from "./EditDeletUserComp.vue?vue&type=style&index=0&id=e0d6b35a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0d6b35a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardActions,VDialog,VForm,VIcon,VSnackbar,VSpacer,VTextField,VTooltip})
