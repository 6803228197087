<template>
  <v-card class="cardMunicipios rounded-lg" elevation="0">

    <div class="infoMunicipios">
      <div class="boxText">
        <h3 class="textInfo">Municípios do Consórcio</h3>
        <div class="linha"></div>
      </div>

      <modify-municipio :resource="resource"/>
    </div>
    
    <v-row>

      <div v-for="dado in dados" :key="dado.id">
        <v-col>

          <v-chip class="mr-2" color="#27ae60" label>
            <v-icon left color="white" small>
              mdi-city
            </v-icon>
            <span class="cities text-capitalize" v-text="dado.name"></span>
          </v-chip>

        </v-col>
      </div>

    </v-row>

  </v-card>
</template>
<script>

import axios from '@/axios'

import ModifyMunicipiosComp from './ModifyMunicipiosComp.vue'

export default {

  data() {
    return {
      dados: [],
      resource: [],
    };
  },

  name: "MunicipiosComp",
  components: {
    "modify-municipio": ModifyMunicipiosComp
  },

  created(){
    this.initialize()
  },

  methods: {
    initialize () {
      axios.get(`/consortia/${this.$route.params.id}/cities`)
        .then((response) => {
          this.dados = response.data
          this.resource = response.data
        })
    },

  }

};
</script>

<style scoped>

  .cardMunicipios {
    padding: 35px;
    margin-bottom: 20px;
  }

  .cardMunicipios > .infoMunicipios {
    display: flex;
    justify-content: space-between;
  }

  .boxText {
    margin-bottom: 25px;
  }

  .cardMunicipios > .infoMunicipios > .boxText > h3 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .cardMunicipios > .infoMunicipios > .boxText > .linha {
    background: #18e79a;
    width: 75px;
    height: 4px;
    border-radius: 25px;
  }

  .cities{
    font-family: 'Poppins';
    padding: 8px;

    color: white;
  }

</style>