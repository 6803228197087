<template>
  <v-container class="main">
    <v-sheet
      class="rounded-lg nav d-flex align-center justify-space-between mb-5"
      color="white"
      height="65"
      width="100%"
    >
      <h4>{{ localizacao }}</h4>
    </v-sheet>

    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card class="cardProfile" elevation="0">
          <span>Usuário do sistema SGCS</span>
          <h3>{{ profile_name }}</h3>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" sm="12">
        <v-card class="cardInfos" elevation="0">
          <div class="textInfo mb-5">
            <h4>Informações</h4>
            <div class="linha"></div>
          </div>

          <v-row>
            <v-col cols="12" md="6" sm="12">
              <label for="">CPF</label>
              <h4>{{ profile_cpf }}</h4>
            </v-col>

            <v-col cols="12" md="6" sm="12">
              <label for="">E-mail</label>
              <h4>{{ profile_email }}</h4>
            </v-col>
          </v-row>

          <hr />

          <!-- <div class="textInfo mb-5">
            <h4>Alterar senha</h4>
            <div class="linha"></div>
          </div>

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-alert class="rounded-lg" color="black" elevation="2">
                <h2>Atenção</h2>
                <span
                  >Ao alterar a senha do Sistema de Gestão de Consórcios (SGC),
                  você também estará alterando dos seguintes sistemas: Sistema
                  da APG 1, Sistema da APG 2, Sistema da APG 3. Ao clicar em
                  "CONFIRMAR" você estará afirmando que tem conhecimento das
                  alterações.</span
                >
              </v-alert>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                class="rounded-lg"
                label="Senha atual"
                placeholder="Digite sua senha atual"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                class="rounded-lg"
                label="Senha atual"
                placeholder="Digite sua senha atual"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                class="rounded-lg"
                label="Senha atual"
                placeholder="Digite sua senha atual"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                class="rounded-lg"
                label="Senha atual"
                placeholder="Digite sua senha atual"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn block class="rounded-lg" color="primary" dark
                >Confirmar</v-btn
              >
            </v-col>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import axios from 'axios'
import config from '../../config/config'

export default {
  data() {
    return {
      localizacao: "Meu Perfil:",
      alert: true,
      user_id: localStorage.getItem('userID'),
      profile_name: "",
      profile_cpf: "",
      profile_email: ""
    };
  },
  name: "MainComp",
      
    created(){
        this.initialize()
    },

    methods: {
      initialize () {
        let geToken = localStorage.getItem('token');

        if(geToken == null) {
          this.$router.push('/') 
        }
        
        axios.get(`${config.apiURL}/users/${this.user_id}`)
          .then((response) => {
            this.profile_name = response.data.name
            this.profile_cpf = response.data.cpf
            this.profile_email = response.data.email
          })
      }
    }
};
</script>

<style scoped>
.nav {
  padding: 15px;
}
.nav > h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.cardProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 220px;

  /* border: 1px solid #e5e5e5; */
  border-radius: 8px;
}

.cardProfile > .iconProfile {
  font-size: 120px;
}

.cardProfile > span {
  font-family: "Poppins";
  font-size: 14px;
}

.cardProfile > h3 {
  font-family: "Poppins";
  font-size: 20px;
}

.cardInfos {
  padding: 30px;
}

.cardInfos > .textInfo > h4 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.cardInfos > .textInfo > .linha {
  background: rgb(103, 205, 142);
  width: 75px;
  height: 4px;
  border-radius: 25px;
}

.cardInfos > hr {
  margin: 30px;
  border-color: #ffffff60;
}
.v-alert__content > h2 {
  font: 18px "Poppins";
  font-weight: 600;
  color: #fff;
}

.v-alert__content > span {
  font: 14px "Poppins";
  font-weight: 400;
  color: #fff;
}
</style>
