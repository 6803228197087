<template>
  <v-container class="main">
    <v-sheet
      class="rounded-lg nav d-flex align-center justify-space-between mb-5"
      color="white"
      height="65"
      width="100%"
    >
      <h4>Todos Usuários</h4>
      <v-btn
        class="rounded-lg"
        elevation="0"
        color="primary"
        outlined
        @click="dialog = true"
        v-if="token != null"
      >
        <v-icon small class="mr-2"> mdi-pencil </v-icon>
        <span class="textBtn"> Criar Usuário </span>
      </v-btn>
    </v-sheet>

    <v-card class="rounded-lg" elevation="0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="usuarios"
        :search="search"
        v-if="token != null"
        :footer-props="{
          'items-per-page-text': 'Usuários por Página',
          'page-text': '{0} de {0}',
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <options-comp :resource="item" v-on:eventname="deletarUsuario" />
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialog"
        width="800px"
        class="modelDialog"
        persistent
        v-if="token != null"
      >
        <v-card class="card rounded-lg">
          <div class="banner">
            <h4>Criar Usuário</h4>
          </div>

          <v-form @submit.prevent="create" ref="form" v-model="valid" lazy-validation>
            <v-text-field
              label="Nome"
              placeholder="Nome completo"
              outlined
              v-model="name"
              :rules="nameRules"
              dense
              required
            ></v-text-field>
            <v-text-field
              label="E-mail"
              placeholder="E-mail"
              outlined
              v-model="email"
              :rules="emailRules"
              required
              dense
            ></v-text-field>
            <v-text-field
              label="CPF"
              placeholder="CPF"
              outlined
              v-model="cpf"
              :rules="cpfRules"
              required
              v-mask="'###.###.###-##'"
              dense
            ></v-text-field>
            <v-text-field
              label="Senha"
              placeholder="Senha"
              outlined
              v-model="password"
              :rules="passRules"
              required
              dense
            ></v-text-field>

            <v-card-actions class="cardFooter">
              <v-spacer></v-spacer>

              <v-btn
                class="rounded-lg btn"
                color="red"
                text
                elevation="0"
                @click="dialog = false"
              >
                <span class="textBtn">Cancelar</span>
              </v-btn>

              <v-btn
                class="rounded-lg"
                color="success"
                elevation="0"
                type="submit"
              >
                <span class="textBtn">Enviar dados</span>
                <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "@/axios";

import EditDeletUserComp from "./EditDeletUserComp.vue";

export default {
  name: "Table",

  data() {
    return {
      valid: true,
      name: "",
      nameRules: [
        v => !!v || 'O nome é obrigatório',
        v => (v && v.length >= 5) || 'Preencha com o nome e sobrenome.',
        v => (v && v.length <= 60) || 'Você excedeu o tamanho máximo.',
      ],

      cpf: "",
      cpfRules: [
        v => !!v || 'O CPF é obrigatório',
        v => (v && v.length <= 14) || 'Você excedeu o tamanho máximo.',
      ],

      email: '',
      emailRules: [
        v => !!v || 'O e-mail é obrigatório',
        v => /.+@.+/.test(v) || 'Insira um e-mail válido',
      ],

      password: "",
      passRules: [
        v => !!v || 'A senha é obrigatória',
        v => (v && v.length <= 16) || 'Você excedeu o tamanho máximo.', 
      ],

      dialog: false,
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name",
        },
        { text: "CPF", value: "cpf" },
        { text: "E-mail", value: "email" },
        { text: "Telefone", value: "phone" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      usuarios: [],
      token: "",

      snackbar: false,
      text: "",
      timeout: 2000,
    };
  },

  components: {
    "options-comp": EditDeletUserComp,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let geToken = localStorage.getItem("token");
      this.token = geToken;
    
      axios.get(`/users`).then((response) => {
        this.usuarios = response.data;
      });
    },

    create() {
      this.$refs.form.validate()
      const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, '');

      const dados = new Object();
        dados.name = this.name;
        dados.cpf = newCPF;
        dados.email = this.email;
        dados.password = this.password;

      axios.post(`/users`, dados).then((response) => {
        this.dialog = false;
        this.text = response.data.message;
        this.snackbar = true;
        this.initialize();
      });
    },

    deletarUsuario(resource) {
      axios.delete(`/users/${resource}`).then((response) => {
        this.dialogAPagar = false;
        this.text = response.data.message;
        this.snackbar = true;
      });

      let usuarios = this.usuarios;
      let newArray = usuarios.filter(function (usuarios) {
        return usuarios.id != resource;
      });
      this.usuarios = newArray;
    },
  },
};
</script>

<style scoped>
.nav {
  padding: 15px;
}
.nav > h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.cardConcorcios {
  padding: 30px;
}

.cardConcorcios > .textInfo > h4 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.cardConcorcios > .textInfo > .linha {
  background: orangered;
  width: 75px;
  height: 4px;
  border-radius: 25px;
}

.card {
  padding: 35px;
}

.banner {
  background: #000000;
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  text-transform: none;
}
</style>
